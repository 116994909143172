<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();
const { depositInfoData } = useRabbitDepositStreak();

const handleGiftAction = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "button_right" });
		}
	});
};
</script>

<template>
	<div class="gift-label" @click="handleGiftAction">
		<AText
			v-if="depositInfoData?.canWheel"
			class="text-tlalnepantla text-carabanchel gift-label-counter"
			:modifiers="['bold']"
			>1</AText
		>
		<NuxtImg
			src="/nuxt-img/deposit-streak/mob-nav.png"
			class="logo"
			height="200"
			width="200"
			format="avif"
			loading="lazy"
			alt="streak-nav-mob"
		/>
		<AText :size="12" :modifiers="['semibold', 'uppercase']" class="text-cannes">
			{{ t("depositStreak.mobNav.try") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.gift-label {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 72px;
	width: 72px;
	cursor: pointer;
	border-radius: 8px 0px 0px 8px;
	background: var(--gothenburg);
	position: fixed;
	right: 0;
	bottom: 450px;
	z-index: 99;

	@include media-breakpoint-down(lg) {
		display: none;
	}

	&-counter {
		position: absolute;
		top: -16px;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background: var(--сirebon);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1005;
	}

	.logo {
		height: 72px;
		width: 72px;
		object-fit: cover;
		position: absolute;
		top: -20px;
	}
}
</style>
